<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <Search-bar :inputs="inputs" :selects="selects" @search="search" @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="printer" @click="downloadExcel" :disabled="tableData.length == 0"
            style="margin-right: 10px">导出</a-button>
          <a-button type="primary" icon="save" @click="showBatch('recharge')" style="margin-right: 10px">批量充值</a-button>
          <a-button type="primary" icon="save" @click="showBatch('adjust')" style="margin-right: 10px">批量调整</a-button>
          <a-button type="primary" icon="save" @click="showBatch('individuation')">个性化批量充值</a-button>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :rowKey="(tableData) => tableData.id"
          :pagination="pagination" @change="handleTableChange">
          <span slot="action" slot-scope="record">
            <a-button type="link" @click="showRechargeModal(record)">充值</a-button>
            <a-divider type="vertical" />
            <a-button type="link" @click="showAdjustModal(record)">账户调整</a-button>
          </span>
        </a-table>
      </div>
    </div>

    <!-- 批量导入 -->
    <a-modal v-model="batchVisible" title="个性化批量充值" okText="确定" cancelText="取消" @ok="handleBatch"
      :confirm-loading="confirmLoading">
      <a-form-model ref="batchForm" :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }">
        <a-form-model-item label="文件(请选择.xls格式)">
          <a-upload accept=".xls" @change="changeFile" :before-upload="beforeUpload" :file-list="fileList">
            <a-button type="primary">
              <a-icon type="upload" />导入Excel
            </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="下载">
          <a-button type="primary" @click="handleDonloadTemplate">模板下载</a-button>
        </a-form-model-item>
        <a-form-model-item v-if="withPwd" label="审核人账号">
          <a-input v-model="rechargeOrAdjustParam.auditor" />
        </a-form-model-item>
        <a-form-model-item v-if="withPwd" label="审核人密码">
          <a-input type="password" v-model="rechargeOrAdjustParam.password" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 批量充值/调整 -->
    <a-modal v-model="rechargeOrAdjustVisible" :title="rechargeOrAdjustTitle" :okText="rechargeOrAdjustTitle"
      cancelText="取消" @ok="handleRechargeOrAdjust(rechargeOrAdjust)" :width="1000">
      <a-form-model ref="batchForm" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
        <a-form-model-item :label="rechargeOrAdjustLable">
          <a-input v-model="rechargeOrAdjustParam.money" />
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-input v-model="rechargeOrAdjustParam.remark" :placeholder="rechargeOrAdjustTitle" />
        </a-form-model-item>
        <a-form-model-item>
          <template slot="label">
            <a-checkbox :indeterminate="indeterOffice" :checked="checkAllOffice" @change="onCheckAllOffice">部门
            </a-checkbox>
          </template>
          <a-checkbox-group v-model="rechargeOrAdjustParam.office_ids" style="width:100%;">
            <a-row>
              <a-col style="text-overflow: ellipsis;white-space: nowrap;overflow : hidden;margin:10px 0;"
                v-for="item in officesData" :key="item.value" :span="
                  officesData.length == 1
                    ? 24
                    : officesData.length == 2
                    ? 12
                    : officesData.length == 3
                    ? 8
                    : 6
                ">
                <a-checkbox :value="item.value"></a-checkbox>{{ item.label }}
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item>
          <template slot="label">
            <a-checkbox :indeterminate="indeterPost" :checked="checkAllPost" @change="onCheckAllPost">职务</a-checkbox>
          </template>
          <a-checkbox-group v-model="rechargeOrAdjustParam.post_ids" style="width:100%;">
            <a-row>
              <a-col style="text-overflow: ellipsis;white-space: nowrap;overflow : hidden;margin:10px 0;"
                v-for="item in postsData" :key="item.value" :span="
                  postsData.length == 1
                    ? 24
                    : postsData.length == 2
                    ? 12
                    : postsData.length == 3
                    ? 8
                    : 6
                ">
                <a-checkbox :value="item.value"></a-checkbox>{{ item.label }}
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item v-if="withPwd" label="审核人账号">
          <a-input v-model="rechargeOrAdjustParam.auditor" />
        </a-form-model-item>
        <a-form-model-item v-if="withPwd" label="审核人密码">
          <a-input type="password" v-model="rechargeOrAdjustParam.password" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 单个充值 -->
    <a-modal v-model="singleRechargeVisible" title="单个充值" @ok="handleSingleRecharge" okText="下一步">
      <a-form-model :model="singleRechargeParam" :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }">
        <a-form-model-item label="充值金额">
          <a-input v-model="singleRechargeParam.money" placeholder="请输入充值金额" />
        </a-form-model-item>
        <a-form-model-item label="类型">
          <a-select v-model="singleRechargeParam.type" @change="changeType">
            <a-select-option :value="1">现金充值</a-select-option>
            <a-select-option :value="9">差错充值</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="singleRechargeParam.type == 1" label="充值类型">
          <a-select v-model="singleRechargeParam.cash_type">
            <a-select-option v-for="item in cashtypes" :key="item.key" :value="item.key">{{ item.val }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="withPwd" label="审核人账号">
          <a-input v-model="singleRechargeParam.auditor" />
        </a-form-model-item>
        <a-form-model-item v-if="withPwd" label="审核人密码">
          <a-input type="password" v-model="singleRechargeParam.password" />
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-input v-model="singleRechargeParam.remark" placeholder="单个账户充值" />
        </a-form-model-item>
      </a-form-model>
      
    </a-modal>

    <!-- 单个调整 -->
    <a-modal v-model="singleAdjustVisible" title="账户调整" @ok="handleSingleAdjust" okText="下一步">
      <a-form-model :model="singleAdjustParam" :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }">
        <a-form-model-item label="调整金额">
          <a-input v-model="singleAdjustParam.money" placeholder="请输入调整后金额，只能减少调整" />
        </a-form-model-item>
        <a-form-model-item label="类型">
          <a-select v-model="singleAdjustParam.type" @change="changeTypeAdjust">
            <a-select-option :value="3">个别调整</a-select-option>
            <a-select-option :value="10">差错调整</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="withPwd" label="审核人账号">
          <a-input v-model="singleAdjustParam.auditor" />
        </a-form-model-item>
        <a-form-model-item v-if="withPwd" label="审核人密码">
          <a-input type="password" v-model="singleAdjustParam.password" />
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-input v-model="singleAdjustParam.remark" placeholder="单个账户调整" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
//引入接口api
import {
  accounts,
  accountDownloadTemplate,
  accountUploadPersonalized,
  updatePersonalized,
  manyRecharge,
  manyAdjustment,
  getAudit,
  singleRecharge,
  singleAdjustment,
  userLog
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
    MyBreadcrumb,
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "账户管理",
        },
        {
          key: 1,
          title: "账户充值",
        },
      ],

      //传给searchBar公共搜索组件的数据
      inputs: [
        {
          placeholder: "姓名",
          model: "realname",
          width: 100,
        },
        {
          placeholder: "手机",
          model: "mobile",
          width: 120,
        },
      ],
      selects: [
        {
          model: "office_id",
          placeholder: "部门",
          width: 200,
          options: [
            {
              key: "",
              value: "部门",
            },
          ],
        },
        {
          model: "post_id",
          placeholder: "职务",
          width: 100,
          options: [
            {
              key: "",
              value: "职务",
            },
          ],
        },
      ],

      //请求参数
      queryParam: {
        page: 1,
        office_id: "",
        post_id: "",
        mobile: "",
        realname: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      dataForExport: [], //不分页的数据
      loading: false, //table加载状态

      // 表格的列配置
      columns: [
        {
          title: "科室",
          key: "user_office",
          dataIndex: "user_office",
        },
        {
          title: "职务",
          key: "user_post",
          dataIndex: "user_post",
        },
        {
          title: "姓名",
          key: "realname",
          dataIndex: "realname",
        },
        {
          title: "手机",
          key: "mobile",
          dataIndex: "mobile",
        },
        {
          title: "余额",
          key: "user_money",
          dataIndex: "user_money",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      //页配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },

      //部门列表数据
      officesData: [],
      //职务数据
      postsData: [],

      //批量更新
      batchVisible: false,
      confirmLoading: false,
      fileList: [],

      //批量充值/调整
      withPwd: false, //判断是否需要输入账号和密码
      rechargeOrAdjustVisible: false,
      rechargeOrAdjustTitle: "",
      rechargeOrAdjustLable: "",
      rechargeOrAdjust: "",
      rechargeOrAdjustParam: {
        money: "",
        office_ids: [],
        post_ids: [],
        auditor: "",
        password: "",
        remark:''
      },


      indeterOffice: false, //控制部门全选样式
      checkAllOffice: false, //部门全选标识

      indeterPost: false, //控制职务全选样式
      checkAllPost: false, //职务全选标识

      //单个充值参数
      cashtypes: [
        { key: 1, val: "现金" },
        { key: 2, val: "微信" },
        { key: 3, val: "支付宝" },
        { key: 4, val: "银行卡" },
        { key: 5, val: "其他" },
      ],
      singleRechargeParam: {
        id: "",
        money: "",
        type: 1, //1现金充值 9差错充值
        cash_type: 1,
        type_text: "现金充值",
        auditor: "",
        password: "",
        remark:'单个充值'
      },
      singleRechargeVisible: false,

      //单个账户调整
      singleAdjustParam: {
        id: "",
        money: "",
        type: 3, //3个别调整 10差错调整
        type_text: "个别调整",
        auditor: "",
        password: "",
        remark:""
      },
      singleAdjustVisible: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //刷新和搜索一样的方法
    search (val) {
      this.queryParam = {
        page: 1,
        mobile: val.input.mobile ? val.input.mobile : "",
        realname: val.input.realname ? val.input.realname : "",

        office_id: val.select.office_id ? val.select.office_id : "",
        post_id: val.select.post_id ? val.select.post_id : "",
      };
      this.pagination.current = 1;
      this.getTableData();

      this.selects = [
        {
          model: "office_id",
          placeholder: "部门",
          width: 200,
          options: [
            {
              key: "",
              value: "部门",
            },
          ],
        },
        {
          model: "post_id",
          placeholder: "职务",
          width: 100,
          options: [
            {
              key: "",
              value: "职务",
            },
          ],
        },
      ];
    },

    //获取表格数据
    getTableData () {
      this.loading = true;
      this.queryParam.no_paging = 0;
      accounts(this.queryParam).then((res) => {
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = res.data.list.total;
        this.pagination = pagination;
        if (res.data.list.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        this.tableData = res.data.list.data;
        this.officesData = res.data.office.map((item) => {
          item = {
            value: item.id,
            label: item.title,
          };
          return item;
        });
        this.postsData = res.data.post.map((item) => {
          item = {
            value: item.id,
            label: item.title,
          };
          return item;
        });
        for (let i = 0; i < this.officesData.length; i++) {
          this.selects[0].options.push({
            key: this.officesData[i].value,
            value: this.officesData[i].label,
          });
        }
        for (let i = 0; i < this.postsData.length; i++) {
          this.selects[1].options.push({
            key: this.postsData[i].value,
            value: this.postsData[i].label,
          });
        }
        this.loading = false;
      });

      //请求不分页的数据
      this.queryParam.no_paging = 1;
      accounts(this.queryParam).then((res) => {
        this.dataForExport = res.data.list;
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //导出
    downloadExcel () {
      this.$confirm({
        title: "确定导出表格?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.export2Excel();
        },
        onCancel: () => { },
      });
    },

    //数据写入excel
    export2Excel () {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); //这里必须使用绝对路径，使用@/+存放export2Excel的路径
        const tHeader = ["姓名", "手机", "科室/部门", "职务", "余额"]; // 导出的表头名信息
        const filterVal = [
          "realname",
          "mobile",
          "user_office",
          "user_post",
          "user_money",
        ]; // 导出的表头字段名，需要导出表格字段名
        const list = that.dataForExport;
        const data = that.formatJson(filterVal, list);

        export_json_to_excel(tHeader, data, "会员账户列表"); // 导出的表格名称，根据需要自己命名
        this.userLogFn()
      });
    },

    //格式转换
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    //打开个性化批量充值导入模态框
    showBatch (val) {
      this.rechargeOrAdjustParam = {
        money: "",
        office_ids: [],
        post_ids: [],
        auditor: "",
        password: "",
        remark:''
      };
      this.checkAllOffice = false;
      this.checkAllPost = false;
      if (val == "recharge") {
        this.getAuditFn();
        this.rechargeOrAdjustTitle = "批量充值";
        this.rechargeOrAdjustLable = "充值金额";
        this.rechargeOrAdjust = "recharge";
        this.rechargeOrAdjustVisible = true;
      }
      if (val == "adjust") {
        this.getAuditFn();
        this.rechargeOrAdjustTitle = "批量调整";
        this.rechargeOrAdjustLable = "调整金额至";
        this.rechargeOrAdjust = "adjust";
        this.rechargeOrAdjustVisible = true;
      }
      if (val == "individuation") {
        this.getAuditFn();
        this.fileList = [];
        this.batchVisible = true;
      }
    },

    //模板下载
    handleDonloadTemplate () {
      accountDownloadTemplate().then((res) => {
        util.acceptFileStream(res.data, "个性化批量充值模板.xls");
      });
    },

    beforeUpload (file) {
      this.fileList = [...this.fileList, file];
      return false;
    },

    //上传文件
    changeFile (info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      this.fileList = fileList;
    },

    //批量导入
    handleBatch () {
      if (this.fileList.length == 0) {
        this.$message.info("请先上传文件");
        return;
      }
      if (this.withPwd) {
        //如果需要输入账号跟密码需要再判断账号密码是否为空
        if (this.rechargeOrAdjustParam.auditor == "") {
          this.$message.warning("请输入审核人账号");
          return;
        }
        if (this.rechargeOrAdjustParam.password == "") {
          this.$message.warning("请输入审核人密码");
          return;
        }
      }
      this.confirmLoading = true
      const formData = new FormData();
      formData.append("personal_file", this.fileList[0].originFileObj);
      accountUploadPersonalized(formData)
        .then((res) => {
          var _this = this;
          _this.batchVisible = false;
          let data = res.data,
            h = _this.$createElement;
          let err_list = data.error_msg.map(item => {
            return h('p', item.join('，'))
          })
          err_list.unshift(h('p', data.msg))
          this.confirmLoading = false
          _this.$confirm({
            title: "是否执行上传操作？",
            content: h('div', {}, err_list),
            okText: "确定",
            cancelText: "取消",
            width: 520,
            okButtonProps: {
              props: {
                disabled: data.length != 0 && data.error_msg.length == 0 ? false : true
              }
            },
            onOk () {
              let auditor = _this.rechargeOrAdjustParam.auditor, password = _this.rechargeOrAdjustParam.password;
              updatePersonalized({ data: data.data, auditor, password }).then((res) => {
                _this.$message.success("个性化批量充值成功");
                _this.batchVisible = false;
                this.getTableData()
              }).catch(err => {
                _this.$message.error(err.data.msg)
              })

            },
          });
        })
        .catch((err) => {
          this.batchVisible = false;
          this.$notification.open({
            message: "导入失败",
            description: "请检查文件内容的格式",
            duration: 0,
          });
        });
    },

    //全选部门
    onCheckAllOffice (e) {
      this.rechargeOrAdjustParam.office_ids = e.target.checked
        ? this.officesData.map((item) => {
          return (item = item.value);
        })
        : [];
      this.indeterOffice = false;
      this.checkAllOffice = e.target.checked;
    },

    //全选职务
    onCheckAllPost (e) {
      this.rechargeOrAdjustParam.post_ids = e.target.checked
        ? this.postsData.map((item) => {
          return (item = item.value);
        })
        : [];
      this.indeterPost = false;
      this.checkAllPost = e.target.checked;
    },

    //批量充值/调整
    handleRechargeOrAdjust (val) {
      var _this = this;
      if (_this.rechargeOrAdjustParam.money == "") {
        _this.$message.warning("请输入金额");
        return;
      }
      if (_this.rechargeOrAdjustParam.office_ids.length == 0) {
        _this.$message.warning("请选择部门");
        return;
      }
      if (_this.rechargeOrAdjustParam.post_ids.length == 0) {
        _this.$message.warning("请选择职务");
        return;
      }
      if (_this.withPwd) {
        //如果需要输入账号跟密码需要再判断账号密码是否为空
        if (_this.rechargeOrAdjustParam.auditor == "") {
          _this.$message.warning("请输入审核人账号");
          return;
        }
        if (_this.rechargeOrAdjustParam.password == "") {
          _this.$message.warning("请输入审核人密码");
          return;
        }
      }

      if (_this.rechargeOrAdjustParam.remark == '') {
        _this.rechargeOrAdjustParam.remark = _this.rechargeOrAdjustParam.rechargeOrAdjustTitle;
      }

      if (val == "recharge") {
        //批量充值
        _this.$confirm({
          title: "确定充值？",
          onOk: () => {
            manyRecharge(_this.rechargeOrAdjustParam)
              .then((res) => {})
              .catch((err) => {
                if (err.status == 201) {
                  _this.$message.success("批量充值成功");
                  _this.getTableData();
                  _this.rechargeOrAdjustVisible = false;
                }
                if (err.status == 400) {
                  _this.$message.error("账号或密码错误请稍后再试");
                  _this.rechargeOrAdjustVisible = false;
                }
                if (err.status == 422) {
                  let { errors } = err.data;
                  for (let i in errors) {
                    this.$message.error(errors[i][0]);
                  }
                }
              });
          },
        });
      }
      if (val == "adjust") {
        //批量调整
        _this.$confirm({
          title: "确定调整？",
          onOk: () => {
            manyAdjustment(_this.rechargeOrAdjustParam)
              .then((res) => {})
              .catch((err) => {
                if (err.status == 201) {
                  _this.$message.success("批量调整成功");
                  _this.rechargeOrAdjustVisible = false;
                  this.getTableData();
                }
                if (err.status == 400) {
                  _this.$message.error("账号或密码错误请稍后再试");
                  _this.rechargeOrAdjustVisible = false;
                }
                if (err.status == 422) {
                  let { errors } = err.data;
                  for (let i in errors) {
                    this.$message.error(errors[i][0]);
                  }
                }
              });
          },
        });
      }
    },

    //查询是否需要输入账号密码
    getAuditFn () {
      getAudit({ type: 1 }).then((res) => {
        if (res.data == true) {
          this.withPwd = true;
        } else {
          this.withPwd = false;
        }
      });
    },

    //显示单个充值弹窗
    showRechargeModal (record) {
      this.getAuditFn();
      this.singleRechargeParam = {
        id: record.id,
        money: "",
        type: 1, //1现金充值 9差错充值
        cash_type: 1,
        type_text: "现金充值",
        auditor: "",
        password: "",
        remark:''
      };
      this.singleRechargeVisible = true;
    },

    changeType (val) {
      if (val == 1) {
        this.singleRechargeParam.type_text = "现金充值";
      } else {
        this.singleRechargeParam.type_text = "差错充值";
      }
    },

    //单个充值
    handleSingleRecharge: util.DebounceBy(function () {
      if (this.singleRechargeParam.money === "") {
        this.$message.info("请输入金额");
        return;
      }
      if (this.singleRechargeParam.auditor === "" && this.withPwd) {
        this.$message.info("请输入审核人账号");
        return;
      }
      if (this.singleRechargeParam.password === "" && this.withPwd) {
        this.$message.info("请输入审核人密码");
        return;
      }
      if (this.singleRechargeParam.remark === "") {
        this.singleRechargeParam.remark = '单个账户充值';
      }
      singleRecharge(this.singleRechargeParam)
        .then((res) => { })
        .catch((err) => {
          if (err.status == 201) {
            this.$message.success("充值成功");
            this.singleRechargeVisible = false;
            // this.pagination.current = 1;
            // this.queryParam.page = 1;
            this.getTableData();
          } else {
            this.$message.error(err.data.msg);
          }
        });
    }, 3000),

    //显示单个调整
    showAdjustModal (record) {
      this.getAuditFn();
      this.singleAdjustParam = {
        id: record.id,
        money: "",
        type: 3, //3个别调整 10差错调整
        type_text: "个别调整",
        auditor: "",
        password: "",
        remark:''
      };
      this.singleAdjustVisible = true;
    },

    changeTypeAdjust (val) {
      if (val == 3) {
        this.singleAdjustParam.type_text = "个别调整";
      } else {
        this.singleAdjustParam.type_text = "差错调整";
      }
    },

    handleSingleAdjust: util.DebounceBy(function () {
      if (this.singleAdjustParam.money === "") {
        this.$message.info("请输入金额");
        return;
      }
      if (this.singleAdjustParam.auditor === "" && this.withPwd) {
        this.$message.info("请输入审核人账号");
        return;
      }
      if (this.singleAdjustParam.password === "" && this.withPwd) {
        this.$message.info("请输入审核人密码");
        return;
      }
      if (this.singleAdjustParam.remark === "") {
        this.singleAdjustParam.remark = '单个账户调整';
      }
      singleAdjustment(this.singleAdjustParam)
        .then((res) => { })
        .catch((err) => {
          if (err.status == 201) {
            this.$message.success("调整成功");
            this.singleAdjustVisible = false;
            // this.pagination.current = 1;
            // this.queryParam.page = 1;
            this.getTableData();
          } else {
            this.$message.error(err.data.msg);
          }
        });
    }, 3000),

    userLogFn(){
      let moduleId = this.$route.meta.moduleId,
          param = {
            name:this.queryParam.realname,
            mobile:this.queryParam.mobile,
            office:this.queryParam.office_id ? this.selects[0].options.find(item => item.key === this.queryParam.office_id).value : '',
            post:this.queryParam.post_id ? this.selects[1].options.find(item => item.key == this.queryParam.post_id).value : '',
            total:this.dataForExport.length
          };
      userLog(moduleId,param).then(res => {}).catch(err => {
        console.log('userLog接口请求错误：')
        console.log(err)
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>